<template>
    <rq-page-section title="Manage Settlement Statement" header-size="sm" section-group="settlement-dashboard" collapsible>
        <template #header-actions>
            <slot name="header-actions"></slot>
        </template>
        <div class="row section-links">
            <div
                v-for="(group,index) in linkGroups"
                :key="group.key"
                :class="{ 'col col-sm-12 col-md-4 col-xl-3':true, 'mt-sm-3 mt-md-0': index > 0 }">
                <div class="h6">{{group.title}}</div>
                <div v-for="groupLink in group.links" :key="groupLink.routeName">
                    <rq-report-button
                        v-if="group.report"
                        :automation_id="groupLink.automation_id"
                        :text="groupLink.label"
                        buttonType="link"
                        :disabled="readOnly"
                        :path="groupLink.routeName"
                        :report-options="groupLink.params"
                    />
                    <b-btn
                        v-else-if="group.isCalculator && dashboardDialogEnabled"
                        :automation_id="groupLink.automation_id"
                        variant="link"
                        class="btn-theme"
                        @click="onLaunchCalculator(groupLink)">{{groupLink.label}}
                    </b-btn>                    
                    <b-btn
                        v-else
                        :automation_id="groupLink.automation_id"
                        variant="link"
                        class="btn-theme"
                        :to="{ name: groupLink.routeName }">{{groupLink.label}}
                    </b-btn>
                </div>
            </div>
        </div>
    </rq-page-section>
</template>

<script>
    import { computed } from "vue";
    import { mapState } from "vuex";
    import { SETTLEMENT_TYPE } from '@settlement/models';
    import { SettlementReport } from '@settlement/components/dashboard/models';
    import { useSettlementStatementCalculators } from '@settlement/SettlementStatementCalculators';
    import { useLicenseStore } from "@/store/modules/license";

    export default {
        name: "SettlementRoutesSection",
        setup() {
            const licenseStore = useLicenseStore();
            const dashboardDialogEnabled = computed(() => licenseStore.features?.dashboardDialogs || false);
            const { launchCalculator } = useSettlementStatementCalculators();
            
            function onLaunchCalculator(e) {
                let okDialogOnly = e.routeName === "settlement:prorations";
                launchCalculator({ calculator: e.routeName, title: e.label, onCancel: ()=>true, okOnly: okDialogOnly });
            } 

            return {
                dashboardDialogEnabled,
                onLaunchCalculator
            }
        },        
        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                orderSummary: state => state.orders.orderSummary,
                isFileLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                loans: state => state.orders.loans || [],
                cdfSections: state => state.orders.orderLookups.cdfSections,
                readOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                selectedView: state => _.parseNumber(state.orders.orderSummary.settlementStatementType, SETTLEMENT_TYPE.CDF),
                isWithOutSeller: state => _.parseBool(state.orders.orderSummary.isWithOutSeller),
                properties: state => state.properties.properties
            }),
            hasEscrowChecksOrDeposits() { return _.get(this, "orderSummary.hasEscrowChecksOrDeposits", false); },
            includeAlta() { return this.orderSummary.includeAlta; },
            isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
            isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            MainID() { return this.$parent.MainID; },
            hasMultipleProperties(){ return this.properties.length > 1 },
            linkGroups() {
                const self = this;
                let groups = [
                    {
                        key: "sections-group",
                        title: "Sections",
                        links: [
                            { label: self.isCdf ? "Loan Charges (A)" : "Loan Charges (800)", routeName: "settlement:origination-section", visible: true },
                            { label: "Title Charges (B & C)", routeName: "settlement:title-section", visible: self.isCdf },
                            { label: self.isCdf ? "Prepaids (F)" : "Prepaids (900)", routeName: "settlement:prepaid-section", visible: true },
                            { label: self.isCdf ? "Escrows/Impounds (G)" : "Escrows/Impounds (1000)", routeName: "settlement:escrow-section", visible: true },
                            { label: "Title Charges (1100)", routeName: "settlement:title-section", visible: self.isHud },
                            { label: self.isCdf ? "Other Charges (H)" : "Other Charges (1300)", routeName: "settlement:othercost-section", visible: true },
                            { label: "Debits/Credits", routeName: "settlement:debitcredits-section", visible: !self.isWithOutSeller },
                            { label: "Payoffs & Payments (K)", routeName: "settlement:cdfPayoffPayments", visible: self.isCdf && self.isWithOutSeller },
                            { label: "Disbursements (1500)", routeName: "settlement:net-disbursement-disb", visible: self.isHud && self.isWithOutSeller },
                            { label: "Net Settlement (1600)", routeName: "settlement:net-disbursement-net", visible: self.isHud && self.isWithOutSeller },
                        ]
                    },
                    {
                        key: "calculators-group",
                        title: "Calculators",
                        isCalculator: true,
                        links: [
                            { label: "Aggregate Adjustment", routeName: "settlement:aggregate-adjustment", visible: true },
                            { label: "Commissions", routeName: "settlement:realtor-commission", visible: !self.isWithOutSeller },
                            { label: "Paid By Others", routeName: "settlement:paid-by-others", visible: true },
                            { label: "Prorations", routeName: "settlement:prorations", visible: !self.isWithOutSeller },
                            { label: "Payoffs", routeName: "settlement:payoffs:list", visible: true },
                            { label: "Recording Fees & Taxes", routeName: "settlement:recording-fees-and-taxes", visible: true },
                            { label: "Settlement Fees", routeName: "settlement:fees", visible: true },
                        ]
                    },
                    {
                        key: "disclosures-group",
                        title: "Disclosures",
                        links: [
                            { label: "801 Breakout Charges", routeName: "settlement:worksheet801", visible: self.isHud2010 },
                            { label: "Addendums", routeName: "settlement:hudAddendums", visible: self.isHud },
                            { label: "Addendums", routeName: "settlement:addendums", visible: self.isCdf },
                            { label: "Additional Title Services", routeName: "settlement:additional-title-services", visible: self.isHud2010 },
                            { label: "Loan Terms", routeName: "settlement:loanterm-section", visible: (self.isCdf) || self.isHud2010 },
                            { label: "Loan Calculations", routeName: "settlement:loan-calculation", visible: self.isCdf },
                            { label: "Projected Payments", routeName: "settlement:projectedpayment-section", visible: self.isCdf },
                            { label: "Cash To Close", routeName: "settlement:cashtoclose-section", visible: self.isCdf },
                            { label: "Loan Disclosures", routeName: "settlement:loan-disclosure-section", visible: self.isCdf },
                            { label: "CDF Contacts", routeName: "settlement:contacts:contact-cards", visible: self.isCdf },
                            { label: "File Summary", routeName: "settlement:settlement-order-summary", visible: true },
                            { label: "GFE Comparison", routeName: "settlement:gfe-comparison", visible: self.isHud2010 },
                        ]
                    },
                    {
                        key: "reports-group",
                        title: "Reports",
                        report: true,
                        links: [
                            { label: "801 Breakout Charges", routeName: SettlementReport.reportOptions801Breakout(self.MainID).reportPath, params: SettlementReport.reportOptions801Breakout(self.MainID), visible: self.isHud2010 },
                            { label: "Additional Title Services", routeName: SettlementReport.reportOptionsAdditionalTitleServices(self.MainID).reportPath, params: SettlementReport.reportOptionsAdditionalTitleServices(self.MainID), visible: self.isHud2010},
                            { label: "Addendums", routeName: SettlementReport.reportOptionsCDFAddendums(self.MainID).reportPath, params: SettlementReport.reportOptionsCDFAddendums(self.MainID), visible: self.isCdf},
                            { label: "Addendums", routeName: SettlementReport.reportOptionsHudAddendums(self.MainID).reportPath, params: SettlementReport.reportOptionsHudAddendums(self.MainID), visible: self.isHud},
                            { label: "Commission Check", routeName: SettlementReport.reportOptionsCommissionCheck(self.orderId).reportPath, params: SettlementReport.reportOptionsCommissionCheck(self.orderId, self.selectedView), visible: !self.isWithOutSeller},
                            { label: "Lender Loan Breakdown", routeName: SettlementReport.reportOptionsLLBreakdown(self.orderId, self.selectedView).reportPath, params: SettlementReport.reportOptionsLLBreakdown(self.orderId, self.selectedView, self.mapSettlementStatementAllLoan), visible: true },
                            { label: "Paid By Others", routeName: SettlementReport.reportOptionsPaidByOthers(self.orderId, self.orderSummary.defaultLoanID).reportPath, params: SettlementReport.reportOptionsPaidByOthers(self.orderId, self.orderSummary.defaultLoanID), visible: true},
                            { label: "Proration Addendum", routeName: SettlementReport.reportOptionsProrationAddendum(self.orderId).reportPath, params: SettlementReport.reportOptionsProrationAddendum(self.orderId), visible: self.hasMultipleProperties},
                            { label: "Recording Itemization", routeName: SettlementReport.reportOptionsRecordingItems(self.orderId, self.orderSummary.defaultLoanID).reportPath, params: SettlementReport.reportOptionsRecordingItems(self.orderId, self.orderSummary.defaultLoanID), visible: self.isCdf},
                        ]
                    }
                ];

                _.each(groups, g => {
                    _.remove(g.links, lnk => !lnk.visible);
                    _.each(g.links, lnk => {
                        let groupPart = _.toLower(g.title);
                        let linkPart = _.toLower(_.snakeCase(lnk.label));
                        lnk.automation_id = `btn_${groupPart}_${linkPart}`;
                    });
                });

                return groups;
            },
        },
        methods:{
            async mapSettlementStatementAllLoan() {
                const self = this;
                let ssType = self.orderSummary.settlementStatementType;
                let apiPromises = [];
                _.forEach(self.loans, (loan) => {
                    apiPromises.push(self.$api.SettlementDashboardApi.mapSettlementDataOverride(ssType, loan.loanID));
                });
                
                try {
                    await self.$rqBusy.wait(Promise.all(apiPromises));
                    return { success: true };
                }
                catch(err) {
                    console.error(err);
                    return { success: false };
                }
            },
        },
    };
</script>
