import { DateTime } from "luxon";

const commissionPayeeType = { Buyer: 1, Seller: 2, ListingAgent: 3, SellingAgent: 4 };
export class CommissionPayeeType {
    static get Buyer () {return commissionPayeeType.Buyer;}
    static get Seller () {return commissionPayeeType.Seller;}
    static get ListingAgent () {return commissionPayeeType.ListingAgent;}
    static get SellingAgent () {return commissionPayeeType.SellingAgent;}
    static get lookupItems () {
        return _.map(commissionPayeeType, c => { return { id: c, name: CommissionPayeeType.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(commissionPayeeType, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}


const calculateAmountWithSplit = function(splitAmt, totalAmt, splitPct, remainderPct){
    let totalVal = _.parseNumber(totalAmt, 0);
    let splitVal = _.parseNumber(splitAmt, 0);
    let newAmount_a = splitVal * (splitPct/100);
    let newAmount_b = (totalVal - splitVal) * (remainderPct/100);
    return newAmount_a + newAmount_b;
};

const parseFixed = function(v, d) { return accounting.parse(accounting.toFixed(v, d)); };

const commissionType = {Buyer: 1, Seller: 2}

export class CommissionType{
    static get Buyer(){ return commissionType.Buyer;}
    static get Seller(){ return commissionType.Seller;}
    static get lookupItems(){
        return _.map(commissionType, c => {return {id: c, name: CommissionType.displayValue(c)}; });
    }

    static displayValue(id){
        return id===commissionType.Buyer ? "Buyer Commission" : "Seller Commission";
    }
}

export class RealtorCommissionModel {
    constructor(data, type, desc) {
        data = data || {};
        this.data = data;
        this.type = type;
        this.description = desc;

        this.loadFromSource();

    }

    loadFromSource(){
        let payee = {};
        if(this.type === CommissionType.Buyer){
            this.commissionId = CommissionType.Buyer;
            payee = this.data.sellingAgentPayee || {};
        }
        else{
            this.commissionId = CommissionType.Seller;
            payee = this.data.listingAgentPayee || {};
        }

        this.companyName = payee.companyName;
        this.companyID = _.parseNumber(payee.companyID, null);
        this.contactName = payee.contactName;
        this.contactID = _.parseNumber(payee.contactID, null);
        this._commissionPercent = _.parseNumber(payee.commissionPercent, 0) / 100;
        this._commissionSplitPercent = _.parseNumber(payee.commissionSplitPercent, 0) / 100;
        this.commissionAmount = _.parseNumber(payee.commissionAmount, 0);
        this.commissionAmountOverride = _.parseNumber(payee.commissionAmountOverride, 0);
        this.commissionSplitAmount = _.parseNumber(payee.commissionSplitAmount, 0);
        this.commissionSplitAmountOverride = _.parseNumber(payee.commissionSplitAmountOverride, 0);
        this.pocAmount = _.parseNumber(payee.pocAmount, 0);
        this.pocWhom = payee.pocWhom || 0;
        this.calcCommissionAmount();
        if(this.useSplit)
            this.calcCommissionSplitAmount();
    }

    calcCommissionAmount(){
        let splitAmount = this.data.splitAtAmountOverride == 0 ? this.data.splitAtAmount : this.data.splitAtAmountOverride;
        let baseCommission = this.useSplit ? (splitAmount * this._commissionPercent) : (this.data.salesPriceOverride * this._commissionPercent);
        if(!this.hasCommissionAmountOverride){
            this.commissionAmountOverride = baseCommission;
        }
        this.commissionAmount = baseCommission;
    }

    calcCommissionSplitAmount(){
        let splitAmount = this.data.splitAtAmountOverride == 0 ? this.data.splitAtAmount : this.data.splitAtAmountOverride;
        let commissionSplitAmount = ((this.data.salesPriceOverride - splitAmount) * this._commissionSplitPercent);
        if(!this.hasCommissionSplitAmountOverride){
            this.commissionSplitAmountOverride = commissionSplitAmount;
        }
        this.commissionSplitAmount = commissionSplitAmount;
    }
    get useSplit() { return this.data.isSplitCommission && this.commissionSplitPercent > 0 && this.splitAtAmountOverride; }
    get isBuyerCommission() { return this.type === CommissionType.Buyer }
    get commissionPercent(){ return this._commissionPercent;}
    set commissionPercent(val){
        let value = _.parseNumber(val, 0);
        this._commissionPercent = value;
        this.calcCommissionAmount();
    }
    get commissionSplitPercent(){ return this._commissionSplitPercent;}
    set commissionSplitPercent(val){
        let value = _.parseNumber(val, 0);
        this._commissionSplitPercent = value;
        this.calcCommissionSplitAmount();
    }
    get hasCommissionAmountOverride(){
        return this.commissionAmount !== this.commissionAmountOverride;
    }
    get hasCommissionSplitAmountOverride(){
        return this.useSplit && this.commissionSplitAmount !== this.commissionSplitAmountOverride;
    }
    get totalCommission(){
        return this.useSplit ? (this.commissionAmountOverride + this.commissionSplitAmountOverride) : (this.commissionAmountOverride);
    }

    get hasOverride() {
        return this.hasCommissionAmountOverride || this.hasCommissionSplitAmountOverride;
    }

    save(){
        let payee = {};
        if(this.type === CommissionType.Buyer){
            payee = this.data.sellingAgentPayee || {};
        }
        else{
            payee = this.data.listingAgentPayee || {};
        }

        payee.companyName = this.companyName;
        payee.companyID = _.parseNumber(this.companyID, null);
        payee.contactName = this.contactName;
        payee.contactID = _.parseNumber(this.contactID, null);
        payee.commissionPercent = _.parseNumber(this.commissionPercent, 0) * 100;
        payee.commissionSplitPercent = _.parseNumber(this.commissionSplitPercent, 0) * 100;
        payee.commissionAmount = _.parseNumber(this.commissionAmount, 0);
        payee.commissionAmountOverride = _.parseNumber(this.commissionAmountOverride, 0);
        payee.commissionSplitAmount = _.parseNumber(this.commissionSplitAmount, 0);
        payee.commissionSplitAmountOverride = _.parseNumber(this.commissionSplitAmountOverride, 0);
        payee.pocAmount = _.parseNumber(this.pocAmount, 0);
        payee.pocWhom = this.pocWhom || 0;
    }
}

export class RealtorCommissionDto {
    constructor(options, isSplitCommission = false) {
        options = options || {};
		this.clientKey = _.uniqueId("realtor-commission-");
        this.realtorCommissionID = _.parseNumber(options.realtorCommissionID, 0);

        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.loanID = _.parseNumber(options.ordersID, 0);
        this.totalSplitPercent = _.parseNumber(options.totalSplitPercent, 0);

        this.isSplitCommission = isSplitCommission;
        this.splitAtAmountOverride = _.parseNumber(options.splitAtAmountOverride, null);
        this.totalPercent = _.parseNumber(options.totalPercent, 0);
        this.totalAmount = _.parseNumber(options.totalAmount, 0);

        this.listingAgentPayeeID = _.parseNumber(options.listingAgentPayeeID, 0);
        let defaultListingAgent = {
            realtorCommissionID: this.realtorCommissionID,
            payeeTypeID: CommissionPayeeType.ListingAgent
        };
        let listingAgent = _.get(options, "listingAgentPayee", defaultListingAgent) || defaultListingAgent;
        this.listingAgentPayee = new RealtorCommissionPayee(listingAgent, isSplitCommission);

        let defaultListingAgentDescription = "Listing Agent Commission";
        if(_.isEmpty(this.listingAgentPayee.description)) {
            this.listingAgentPayee.description = defaultListingAgentDescription;
            this.listingAgentPayee.descriptionOverride = defaultListingAgentDescription;
        }

        this.sellingAgentPayeeID = _.parseNumber(options.sellingAgentPayeeID, 0);
        let defaultSellingAgent = {
            realtorCommissionID: this.realtorCommissionID,
            payeeTypeID: CommissionPayeeType.SellingAgent
        };
        let sellingAgent = _.get(options, "sellingAgentPayee", defaultSellingAgent) || defaultSellingAgent;
        this.sellingAgentPayee = new RealtorCommissionPayee(sellingAgent, isSplitCommission);

        let defaultSellingAgentDescription = "Selling Agent Commission";
        if(_.isEmpty(this.sellingAgentPayee.description)) {
            this.sellingAgentPayee.description = defaultSellingAgentDescription;
            this.sellingAgentPayee.descriptionOverride = defaultSellingAgentDescription;
        }

        this.payees = _.map(_.get(options, "payees", []), p => new RealtorCommissionPayee(p));

        this.salesPrice = _.parseNumber(options.salesPrice, 0);
        this.salesPriceOverride = _.parseNumber(options.salesPriceOverride, 0);

        if(this.salesPriceOverride === 0 && this.salesPrice !== 0)
            this.salesPriceOverride = this.salesPrice;

        this.splitAtAmount = _.parseNumber(options.splitAtAmount, null);
        this.splitAtAmountOverride = _.parseNumber(options.splitAtAmountOverride, null);
        if(_.isNil(this.splitAtAmountOverride) && !_.isNil(this.splitAtAmount))
            this.splitAtAmountOverride = this.splitAtAmount;

		this.description = options.description || null;
		this.dateCreated = options.dateCreated || DateTime.utc().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
		this.createdByID = _.parseNumber(options.createdByID, 0);
		this.createdByUserName = options.createdByUserName || null;
		this.dateModified = options.dateModified || DateTime.utc().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
		this.modifiedByID = _.parseNumber(options.modifiedByID, 0);
        this.modifiedByUserName = options.modifiedByUserName || null;
        this.totalAmountOverridden = this.totalAmountOverrideVal;
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
    }

    setTotalPercent(pct) {
        this.setPercent(pct);
    }

    setTotalSplitPercent(pct) {
        this.setPercent(pct, true);
    }

    setPercent(pct, isSplitPct=false) {
        let totalField = isSplitPct ? "totalSplitPercent" : "totalPercent";
        let agentField = isSplitPct ? "commissionSplitPercent" : "commissionPercent";

        if(!_.isNil(pct)) this[totalField] = _.parseNumber(pct, 0);


        if(this.sellingAgentPayee[agentField] === 0) {
            this.listingAgentPayee[agentField] = this[totalField];
        }
        else {
            this.listingAgentPayee[agentField] = this[totalField] - this.sellingAgentPayee[agentField];
        }
    }

    get useSplitCommission() { return this.isSplitCommission && this.totalSplitPercent > 0 }
    get splitAmount() { return this.splitAtAmountOverride === 0 ? this.splitAtAmount : this.splitAtAmountOverride }

    get salesPriceOverridden() { return !_.eq(_.parseNumber(this.salesPrice, 0), _.parseNumber(this.salesPriceOverride, 0)); }
    get splitAtAmountOverridden() { return !_.eq(_.parseNumber(this.splitAtAmount, 0), _.parseNumber(this.splitAtAmountOverride, 0)); }

    get totalAmountOverrideVal() {
        if (this.useSplitCommission) {
            return !_.eq(this.totalAmount, this.calcSplitTotal())
        }
        else return !_.eq(this.totalAmount, this.calcTotal());
    }

    get totalAmountVal() {
        if (!this.totalAmountOverridden) {
            if (this.useSplitCommission)
                this.totalAmount = this.calcSplitTotal();
            else
                this.totalAmount = this.calcTotal();
        }
        return this.totalAmount;
    }

    set totalAmountVal(val) {
        this.totalAmount = _.parseNumber(val, 0);
    }

    calcSplitTotal() {
        let commissionPercent = parseFixed(this.totalPercent/100, 5);
        let splitPercent = parseFixed(this.totalSplitPercent/100, 5);
        let splitTotalAmount = _.parseNumber(this.salesPriceOverride - this.splitAmount)
        return parseFixed(this.splitAmount * commissionPercent + splitTotalAmount * splitPercent);
    }

    calcTotal() {
        let commissionPercent = parseFixed(this.totalPercent/100, 5);
        return _.parseNumber(this.salesPriceOverride * commissionPercent);
    }

    updateAmounts(splitEnabled=false) {
        if(this.useSplitCommission) {
            this.updateAmountWithSplit();
            return;
        }

        this.sellingAgentPayee.updateAmount(this.salesPriceOverride, this.totalAmountVal);
        if (this.totalAmountOverridden){
            let commissionOverrideAmount = this.totalAmountVal - this.sellingAgentPayee.commissionAmount;
            if (!this.listingAgentPayee.hasCommissionAmountOverride)
                this.listingAgentPayee.commissionAmountOverride = commissionOverrideAmount;
            this.listingAgentPayee.commissionAmount = commissionOverrideAmount;
        }
        else
            this.listingAgentPayee.updateAmount(this.salesPriceOverride, this.totalAmountVal);

    }
    updateAmountWithSplit() {
        this.sellingAgentPayee.updateAmountWithSplit(this.salesPriceOverride, this.splitAmount);
        if (this.totalAmountOverridden){
            let commissionOverrideAmount = this.totalAmountVal - this.sellingAgentPayee.commissionAmount - this.sellingAgentPayee.commissionSplitAmount - this.listingAgentPayee.commissionSplitAmountOverride;
            if (!this.listingAgentPayee.hasCommissionAmountOverride)
                this.listingAgentPayee.commissionAmountOverride = commissionOverrideAmount;
            this.listingAgentPayee.commissionAmount = commissionOverrideAmount;
        }
        else
            this.listingAgentPayee.updateAmountWithSplit(this.salesPriceOverride, this.splitAmount);

    }

    filterItemsBy(roleTypeId) { return _.filter(this.payees, item => item.payeeTypeID === roleTypeId); }

    getItemTotal(roleTypeId) {
        return _.reduce(this.payees, (total, item) => {
            return item.payeeTypeID === roleTypeId ? total + item.commissionAmount : total;
        }, 0);
    }

    toDataObject() {
        let dataObject = _.pickBy(this, (v,k) => { return !_.includes(["clientKey", "splitAtAmount", "listingAgentPayee", "sellingAgentPayee", "payees"], k); });
        dataObject.listingAgentPayee = _.pickBy(this.listingAgentPayee, (v,k) => { return k !== "clientKey"; });
        dataObject.sellingAgentPayee = _.pickBy(this.sellingAgentPayee, (v,k) => { return k !== "clientKey"; });
        dataObject.payees = _.map(this.payees, p => _.pickBy(p, (v,k) => { return k !== "clientKey"; }));
        return dataObject;
    }
}

export class RealtorCommissionPayee {
    constructor(options, isSplitCommission = false) {
        options = options || {};
		this.clientKey = _.uniqueId("realtor-commission-payee-");
        this.realtorCommissionPayeeID = _.parseNumber(options.realtorCommissionPayeeID, 0);
        this.description = options.description || "";
        this.descriptionOverride = options.descriptionOverride || this.description;
        this.commissionPercent = _.parseNumber(options.commissionPercent, 0);
        this.commissionSplitPercent = _.parseNumber(options.commissionSplitPercent, 0);
        this.commissionAmount = _.parseNumber(options.commissionAmount, 0);
        this.commissionAmountOverride = _.parseNumber(options.commissionAmountOverride, 0);
        this.commissionSplitAmount = _.parseNumber(options.commissionSplitAmount, 0);
        this.commissionSplitAmountOverride = _.parseNumber(options.commissionSplitAmountOverride, 0);
        this.companyID = _.parseNumber(options.companyID, null);
        this.companyName = options.companyName || "";
        this.contactID = _.parseNumber(options.contactID, null);
        this.contactName = options.contactName || "";
        this.netFundType = _.parseNumber(options.netFundType, null);
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
        this.itemize = _.parseBool(options.itemize);
        this.deductFromRealtorCheck = _.parseBool(options.deductFromRealtorCheck);
        this.payeeTypeID = _.parseNumber(options.payeeTypeID, commissionPayeeType.Buyer);
		this.dateCreated = options.dateCreated || DateTime.utc().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
		this.createdByID = _.parseNumber(options.createdByID, 0);
		this.createdByUserName = options.createdByUserName || null;
		this.dateModified = options.dateModified || DateTime.utc().toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
		this.modifiedByID = options.modifiedByID || null;
        this.modifiedByUserName = options.modifiedByUserName || null;
        this.cdfLineID = _.parseNumber(options.cdfLineID, null);
        this.hudLineID = _.parseNumber(options.hudLineID, null);
        this.isDeleted = false;
        this.pocAmount = _.parseNumber(options.pocAmount, null);
        this.pocWhom = _.parseNumber(options.pocWhom, 0);
        this.useSplit = isSplitCommission;
        this.isDeducted = _.parseBool(options.isDeducted);
        this.earnestMoney = _.parseNumber(options.earnestMoney, null);
        this.isPaidByBuyer = _.parseBool(options.isPaidByBuyer);
    }

    get descriptionOverridden() { return !_.isEmpty(this.descriptionOverride) && this.description !== this.descriptionOverride; }

    get hasCommissionAmountOverride(){
        return this.commissionAmount !== this.commissionAmountOverride;
    }

    updateAmount(salesPrice) {
        let newAmount = _.parseNumber(salesPrice, 0) * (this.commissionPercent/100);
        // RQO-13637: Clear the split amounts when not using split calculation
        if(this.commissionSplitAmount === this.commissionSplitAmountOverride)
            this.commissionSplitAmountOverride = 0;
        this.commissionSplitAmount = 0;

        if(this.commissionAmount === this.commissionAmountOverride) this.commissionAmountOverride = parseFixed(newAmount, 2);
        this.commissionAmount = parseFixed(newAmount, 2);
    }

    updateAmountWithSplit(salesPrice, splitAt) {
        let baseCommission = splitAt * (this.commissionPercent/100);
        let splitCommission = (salesPrice - splitAt) * (this.commissionSplitPercent/100);
        if(this.commissionAmount === this.commissionAmountOverride) this.commissionAmountOverride = parseFixed(baseCommission);
        this.commissionAmount = parseFixed(baseCommission);
        if(this.commissionSplitAmount === this.commissionSplitAmountOverride) this.commissionSplitAmountOverride = parseFixed(splitCommission);
        this.commissionSplitAmount = parseFixed(splitCommission);
    }

    get totalCommission(){
        return this.hasCommissionAmountOverride ? this.commissionAmountOverride : this.useSplit ? (this.commissionAmountOverride + this.commissionSplitAmountOverride) : (this.commissionAmountOverride);
    }

    set totalCommission(val){
        //stub so the console doesn't throw vue error
    }

	get company() {
        return {
            companyID: this.companyID,
            companyName: this.companyName,
            contactID: this.contactID,
            contactName: this.contactName
        };
    }
	set company(val) {
		this.companyID = _.get(val, "companyID", null);
		this.companyName = _.get(val, "companyName", null);
		this.contactID = _.get(val, "contactID", null);
		this.contactName = _.get(val, "contactName", null);
	}
}
