<template>
    <div class="order-main-content content-wrapper" v-if="order">
        <!--validation summary-->
        <div class="row">
            <div class="col-12">
                <rqValidationSummary :dataSource="validationErrors" />
            </div>
        </div>
        <rq-banner
            variant="error"
            :title="escrowErrorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showEarnestErrorBanner || hasErrors || !customDataIsValid"/>
        <rq-page-section title="File Details" collapsible>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-section-expand-collapse-all />
                    </li>
                </ul>
            </template>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <!--status-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_status">Status</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_status', automation_id:'drp_status' }"
                            :items="formattedStatus"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.status"
                            :disabled="readOnly"
                            v-focus.input
                        />
                    </div>

                    <!--title company-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">

                        <label for="drp_title_company">Title Company</label>
                        <dx-select-box
                            ref="titleCompanyDropDownBox"
                            :input-attr="{ id: 'drp_title_company', automation_id: 'drp_title_company' }"
                            :items="titleCompanies"
                            value-expr="id"
                            display-expr="name"
                            search-expr="name"
                            field-template="title-company-field"
                            item-template="title-company-item"
                            :search-enabled="true"
                            :disabled="readOnly"
                            v-model="order.titleCompanyCompanyID">
                            <template #title-company-field="{ data }">
                                <dx-text-box :input-attr="{ automation_id: 'txt_title_company' }" :value="data ? data.name : ''" />
                            </template>
                            <template #title-company-item="{ data }">
                                <div>
                                    <div><strong>{{data.name}}</strong></div>
                                    <div>{{getCompanyAddress(data)}}</div>
                                </div>
                            </template>
                        </dx-select-box>
                    </div>

                    <!--process template-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_process_template">Process Template</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_process_template', automation_id:'drp_process_template' }"
                            :items="filteredTemplates"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.workflowProcessTemplateID"
                            :disabled="readOnly"
                        />
                    </div>

                    <!--source of business-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_source_of_business">Source of Business</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_source_of_business', automation_id:'drp_source_of_business' }"
                            :items="sourceOfBusinesses"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.sourceOfBusinessID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>

                <div class="row">
                    <!--escrow unit-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_escrow_unit">Escrow Unit</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_escrow_unit', automation_id:'drp_escrow_unit' }"
                            :items="escrowUnits"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.escrowUnitID"
                            :disabled="readOnly"
                        />
                    </div>

                    <!--underwriter-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_underwriter">Underwriter</label>
                        <dx-select-box
                            ref="underwriter"
                            :input-attr="{ id:'drp_underwriter', automation_id:'drp_underwriter' }"
                            :items="underwriters"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.underwriterCompanyID"
                            @valueChanged="onUnderwriterChange"
                            :disabled="readOnly"
                        />
                    </div>

                    <!--order category-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_order_category">File Category</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_order_category', automation_id:'drp_order_category' }"
                            :items="orderCategories"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.orderCategoryID"
                            :disabled="readOnly"

                        />
                    </div>

                    <!--other company's file number-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="other_file_number">Other Company's File #</label>
                        <input automation_id="txt_other_file_number" v-model="order.otherCompaniesFileNumber" type="text" class="form-control" id="other_file_number" placeholder="Other Company's File #" :disabled="readOnly">
                    </div>
                </div>

                <div class="row">
                    <!--escrow account-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3">
                        <rq-action-form-group
                            label="Escrow Account"
                            labelFor="drp_escrow_account"
                            :hasError="showEarnestErrorBanner"
                            v-rq-tooltip.hover.top="{ title: getEscrowAccountToolTip }"
                        >
                            <template #default>
                                <dx-select-box
                                    :input-attr="{ id:'drp_escrow_account', automation_id:'drp_escrow_account' }"
                                    :items="escrowAccounts"
                                    value-expr="id"
                                    display-expr="name"
                                    :search-enabled="true"
                                    :disabled="hasEscrowChecksOrDeposits || receiptsOrDisbursementsPosted || readOnly || isEscrowLocked"
                                    v-rq-tooltip.hover.top="{ title: getEscrowAccountToolTip }"
                                    v-model="order.bankCompanyID"
                                    @valueChanged="onEscrowAccountChange"
                                />
                            </template>
                            <template #below>
                                <template v-if="order.enableMultipleBankAccount">
                                    <b-form-checkbox
                                        automation_id="chk_multipleBankAccount"
                                        id="multipleBankAccount"
                                        v-model="order.multipleBankAccount"
                                        :disabled="!canChangeMultipleBankAccount || readOnly">
                                        Multiple Bank Accounts
                                    </b-form-checkbox>
                                </template>
                            </template>
                        </rq-action-form-group>
                    </div>

                    <!--title unit-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_title_unit">Title Unit</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_title_unit', automation_id:'drp_title_unit' }"
                            :items="titleUnits"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.titleUnitID"
                            :disabled="readOnly"
                        />
                    </div>

                    <!--service-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" :class="{'form-required': !orderSummary.isConsolidatedFile, 'has-error':v$.order.service.$error }">
                        <label for="drp_service">Service</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_service', automation_id:'drp_service' }"
                            :items="orderServices"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="v$.order.service.$model"
                            @valueChanged="onServiceChange"
                            :disabled="readOnly"
                        />
                        <rq-validation-feedback
                            messages="Service Type is Required"
                        />
                    </div>

                    <!--friendly name-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="friendly_name">Friendly Name</label>
                        <input automation_id="txt_friendly_name" v-model="order.friendlyName" type="text" class="form-control" id="friendly_name" placeholder="Friendly Name " :disabled="readOnly">
                    </div>

                    <!-- Transaction Type -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-rq-tooltip.hover.top="{ title: disableTransactionType ? 'Unable to Change the Transaction Type until Receipts & Disbursements are Deleted' : '' }">
                        <label for="drp_transaction_type">Transaction Type</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_transaction_type', automation_id:'drp_transaction_type' }"
                            :items="transactionTypes"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.transactionTypeID"
                            :disabled="readOnly || disableTransactionType"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <!--Critical Dates-->

        <rq-page-section title="Critical Dates" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div key="datetimedate" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" :class="{'form-required' : isCloseDateRequired, 'has-error' : v$.order.closeDate.$error && showValidationError}">
                        <label for="dtp_close_date">Close Date</label>
                        <rqdx-date-box
                            id="dtp_close_date"
                            v-model="calcCloseDate"
                            type="date"
                            display-format="MM/dd/yyyy"
                            :disabled="readOnly"
                            @valueChanged="onCloseDateChanged"
                        />
                        <rq-validation-feedback>Close Date is required if Close Time is provided</rq-validation-feedback>
                    </div>
                    <div key="datetimetime" class="col col-3 col-sm-12 col-md-6 col-lg-3">
                        <rq-action-form-group
                            label="Close Time"
                            labelFor="dtp_close_time"
                            :show-action="true"
                        >
                            <template #action>
                                <b-form-checkbox
                                    id="chk_close_time"
                                    automation_id="chk_close_time"
                                    v-model="enableCloseTime"
                                    :disabled="calcCloseDate == null"
                                >
                                    Enable
                                </b-form-checkbox>
                            </template>
                            <template #default>
                                <rqdx-date-box
                                    id="dtp_close_time"
                                    v-model="calcCloseTime"
                                    :disabled="closeTimeDisabled"
                                    type="time"
                                    display-format="hh:mm a"
                                    auto-open
                                    :date-part="calcCloseDate"
                                />
                            </template>
                        </rq-action-form-group>
                    </div>

                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_duration">Duration</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_duration', automation_id:'drp_duration' }"
                            :items="durations"
                            value-expr="id"
                            display-expr="name"
                            v-model="order.duration"
                            :disabled="readOnly"
                        />
                    </div>

                    <!-- Close Type -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_close_type">Close Type</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_close_type', automation_id:'drp_close_type' }"
                            :items="closingTypes"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.closingTypeID"

                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="dtp_fund_date">Fund/Disbursement Date</label>
                        <rqdx-date-box
                            id="dtp_fund_date"
                            v-model="order.fundDate"
                            @valueChanged="onFundDateChanged"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="dtp_cancelled_date">Cancelled Date</label>
                        <rqdx-date-box
                            id="dtp_cancelled_date"
                            v-model="order.userDefinedDate1"
                            :disabled="readOnly"
                        />
                    </div>
                        <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="dtp_open_date">Open Date</label>
                        <rqdx-date-box
                            id="dtp_open_date"
                            v-model="order.openDate"
                            :disabled="true"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <!-- Sale Information -->
        <rq-page-section title="Sale Information" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_sales_sales_price">Sales Price</label>
                        <rqInputNumber
                            automation_id="txt_sales_sales_price"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="order.salesPrice"
                            :disabled="readOnly"/>
                    </div>

                    <!--Owner Policy Liability Override-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_sales_owner_policy_liability_override">Owner Policy Liability Override</label>
                        <rqInputNumber
                            automation_id="txt_sales_owner_policy_liability_override"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            v-model="order.ownerPolicyLiability"
                            :disabled="readOnly"/>
                    </div>

                    <!--Earnest Money-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_sales_earnest_money">Earnest Money</label>
                        <rqInputNumber
                            automation_id="txt_sales_earnest_money"
                            ref="txt_sales_earnest_money"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            value-event="input"
                            v-model="order.earnestMoney"
                            :disabled="readOnly"/>
                    </div>

                    <!--Earnest Money Holder -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-if="order.earnestMoney">
                        <label for="drp_earnest_money_holder">Earnest Money Holder</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_earnest_money_holder', automation_id:'drp_earnest_money_holder' }"
                            :items="earnestMoneyHolderOptions"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.earnestMoneyHeldBy"
                            @valueChanged="onEarnestMoneyHolderChanged"
                            :disabled="readOnly"
                        />
                    </div>

                    <!--Earnest Money Fund Type -->
                    <div
                        :class="{ 'col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required': true, 'has-error': v$.order.typeFundID.$error }"
                        v-if="order.earnestMoney && (order.earnestMoneyHeldBy === 0 || order.earnestMoneyHeldBy === 6)"
                        v-rq-tooltip.hover.top="{ title: order.lockEarnestMoneyTypeFund ? 'Please use Check Writing to change Type Fund once it\'s created' : '' }"
                        >
                        <label for="drp_escrow_fund_type">Escrow Deposit Fund Type</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_escrow_fund_type', automation_id:'drp_escrow_fund_type' }"
                            :items="typeFunds"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            :disabled="order.lockEarnestMoneyTypeFund && !readOnly"
                            v-model="v$.order.typeFundID.$model"
                        />
                        <rq-validation-feedback
                            messages="Fund Type is Required"
                        />
                    </div>

                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group pt-4" v-if="order.earnestMoney && (order.earnestMoneyHeldBy === 2 || order.earnestMoneyHeldBy === 3)">
                        <b-form-checkbox
                            automation_id="chk_deductAmount"
                            id="deductAmount"
                            v-model="order.deductEarnestMoneyFromCommission">Deduct amount from agent commission check
                        </b-form-checkbox>
                    </div>
                </div>
                <div class="row" v-if="region.isAdditionalDeposit">
                    <!--Additional Deposit/Option Fee-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_sales_earnest_money">{{optionFeeLabel}}</label>
                        <rqInputNumber
                            automation_id="txt_sales_additional_deposit"
                            ref="txt_sales_additional_deposit"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            prependIcon="fas fa-dollar-sign"
                            value-event="input"
                            @change="onAdditionalDepositChange"
                            v-model="order.additionalDeposit"
                            :disabled="readOnly"/>
                    </div>

                    <!-- Option Fee Payable To -->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-if="order.additionalDeposit">
                        <label for="drp_earnest_money_holder">Payable To</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_option_fee_payable_to', automation_id:'drp_option_fee_payable_to' }"
                            :items="optionFeePayableToOptions"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.optionFeePayableTo"
                            :disabled="readOnly"
                        />
                    </div>

                    <!-- Option Fee Escrow Deposit Fund Type -->
                    <div :class="{ 'col col-3 col-sm-12 col-md-6 col-lg-3 form-group form-required':true, 'has-error':v$.order.optionFeeTypeFundID.$error }" v-if="order.additionalDeposit && order.optionFeePayableTo === 0">
                        <label for="drp_escrow_fund_type">Escrow Deposit Fund Type</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_escrow_fund_type', automation_id:'drp_escrow_fund_type' }"
                            :items="optionFeeTypeFunds"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            :disabled="order.lockOptionFeeTypeFund && !readOnly"
                            @valueChanged="onOptionEscrowFundTypeChange"
                            v-model="v$.order.optionFeeTypeFundID.$model"
                        />
                        <rq-validation-feedback
                            messages="Fund Type is Required"
                        />
                    </div>

                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group pt-4" v-if="order.additionalDeposit && (order.optionFeePayableTo === 2 || order.optionFeePayableTo === 3)">
                        <b-form-checkbox
                            automation_id="chk_deduct_option_fee"
                            id="deduct_option_fee"
                            v-model="order.deductOptionFeeFromCommission">Deduct amount from agent commission check
                        </b-form-checkbox>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <!--Staff-->
        <rq-page-section title="Staff" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_sales_representative">Sales Representative</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_sales_representative', automation_id:'drp_sales_representative' }"
                            :items="salesReps"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            :disabled="readOnly || !localSecurity.AllowEditSalesRep"
                            v-model="order.salesRepresentativeStaffID"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_escrow_officer">Escrow Officer</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_escrow_officer', automation_id:'drp_escrow_officer' }"
                            :items="escrowOfficers"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.escrowOfficerStaffID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_title_officer">Title Officer</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_title_officer', automation_id:'drp_title_officer' }"
                            :items="titleOfficers"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.titleOfficerStaffID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_closing_agent">Closing Agent</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_closing_agent', automation_id:'drp_closing_agent' }"
                            :items="closingAgents"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.closeAgentStaffID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_escrow_processor">Escrow Processor</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_escrow_processor', automation_id:'drp_escrow_processor' }"
                            :items="escrowProcessors"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.escrowProcessorStaffID"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_title_processor">Title Processor</label>
                        <dx-select-box
                            :input-attr="{ id:'drp_title_processor', automation_id:'drp_title_processor' }"
                            :items="titleProcessors"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="true"
                            v-model="order.titleProcessorStaffID"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>

        <!-- Parties -->
        <rq-page-section title="Parties" collapsible>
            <div class="row" >

                <!--place of closing-->
                <div class="col-6 form-group">
                    <div class="row sub-group">
                        <div class="col-12 form-group">
                            <label for="place_of_closing">Place of Closing</label>
                            <company-picker
                                ref="refPlaceOfClosing"
                                automation_id="pic_place_of_closing_company"
                                companyRoleName="Place of Closing"
                                :companyRoleId="9"
                                dialogTitle="Select Place of Closing"
                                :disabled="readOnly"
                                v-model="order.placeOfClosingCompany"
                                show-selection-summary
                            />
                        </div>
                        <div v-if="order.hasPlaceOfClosingCompany" class="col-12 form-group">
                            <label for="place_of_closing">Place of Closing Contact</label>
                            <div class="input-group ">
                                <contact-picker
                                    automation_id="drp_place_of_closing_contact"
                                    dialogTitle="Select Place of Closing Contact"
                                    :disabled="readOnly"
                                    :disableAddEdit="!localSecurity.AllowEditContacts"
                                    :company-id="order.placeOfClosingCompanyId"
                                    v-model:contact-name="order.placeOfClosingContactName"
                                    v-model="order.placeOfClosingContactId">
                                </contact-picker>
                            </div>
                        </div>
                        <div v-if="order.hasPlaceOfClosingCompany" class="col-12 form-group mb-0">
                            <label for="place_of_closing_location">Place of Closing Location</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'drp_place_of_closing_location', id: 'place_of_closing_location' }"
                                :items="placeOfClosingLocations"
                                value-expr="id"
                                display-expr="name"
                                v-model="order.locationID"
                                :disabled="readOnly"
                            />
                        </div>
                    </div>
                </div>

                <!--settlement agent-->
                <div class="col-6 form-group">
                    <div class="row sub-group">
                        <div class="col-12 form-group">
                            <label for="settlement_agent">Settlement Agent</label>
                            <company-picker
                                ref="refSettlementAgent"
                                automation_id="pic_settlement_agent_company"
                                companyRoleName="Settlement Agent"
                                :companyRoleId="28"
                                dialogTitle="Select Settlement Agent Company"
                                :disabled="readOnly"
                                v-model="order.settlementAgentCompany"
                                show-selection-summary
                            />
                        </div>
                        <div v-if="order.hasSettlementAgentCompany" class="col-12 form-group mb-0">
                            <label for="settlement_agent">Settlement Agent Contact</label>
                            <div class="input-group">
                                <contact-picker
                                    automation_id="drp_settlement_agent_contact"
                                    dialogTitle="Select Settlement Agent Contact"
                                    :disabled="readOnly"
                                    :disableAddEdit="!localSecurity.AllowEditContacts"
                                    :company-id="order.settlementAgentCompanyId"
                                    v-model:contact-name="order.settlementAgentContactName"
                                    v-model="order.settlementAgentContactId">
                                </contact-picker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </rq-page-section>
        <rq-page-section title="Custom Data" collapsible v-if="displayCustomData" >
            <custom-data-container
                ref="customDataContainer"
                :reference-table="referenceTable"
                :reference-table-pk-value="orderId"
                :custom-data-tab-id="customDataTabID"
                v-model:value="customData"
                v-model:is-valid="customDataIsValid"
                :read-only="customDataReadOnly"
                @custom-data-loaded="onCustomDataLoaded"
            ></custom-data-container>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { useVuelidate } from '@vuelidate/core';
    import { requiredIf, required } from '@vuelidate/validators';
    import { OrderStatus } from '@/shared/models/enums';
    import { ORDER_ACTIONS } from '@/store/actions';
    import { ORDER_MUTATIONS } from '@/store/mutations';
    import { COMMITMENT_ACTIONS } from '@/store/actions';
    import { GlobalEventManager } from '@/app.events';
    import { CompanyPicker, ContactPicker, CustomDataContainer } from '@/shared/components';
    import { CustomDataReferenceTable } from "@/shared/components/customdata/models";
    import { OrderMainDto } from './models';
    import EarnestMoney from './EarnestMoney';
    import { dateTimeSplitDependent } from "@/shared/utilities/VueHelpers";
    import { UserScreenAccessLevel } from "@/shared/models/enums";
    import { DateTime } from "luxon";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    export default {

        name: 'OrderMain',

        components: {
            CompanyPicker,
            ContactPicker,
            CustomDataContainer
        },

        setup: () => ({ v$: useVuelidate() }),

        data () {
            return {
                isLoaded: false,
                promptStaffAssignmentsChange: false,
                order: new OrderMainDto(),
                originalValue: null,
                validationErrors: [],
                showEarnestErrorBanner: false,
                showValidationError: false,
                escrowErrorMessage: null,
                earnestMoneyHolderOptions: [
                    { id: 3, name: "Buyer's Real Estate Agent" },
                    { id: 4, name: "Lender" },
                    { id: 5, name: "No One or Not Listed" },
                    { id: 1, name: "Seller" },
                    { id: 2, name: "Seller's Real Estate Agent" },
                    { id: 6, name: "Settlement Agent" },
                    { id: 0, name: "Title Company" }
                ], // this is also hard-coded in other OrderMain.vue components and the CSSMain.vue component - make sure the options do not go out of sync across components
                optionFeePayableToOptions: [
                    { id: 1, name: "Seller" },
                    { id: 0, name: "Title Company" }
                ],
                // canSeeMultipleBankAccount: false,
                // depositExists: false,
                enableCloseTime: false,
                IN_EARNEST_MONEY: 16, // Seed Data from table TypeFund
                customDataTabID: 0,
                customDataIsValid: true,
                customData: [],
                originalCustomData: [],
                hasErrors: false
            };
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "tenantTimeZone",
                "clientTimeZone"
            ]),
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                isPageReadOnly: state => state.isPageReadOnly,
                storedOrderId: state => state.orders.orderId,
                orderState: state => state.orders.order,
                orderSummary: state => state.orders.orderSummary,
                fileInUse: state => state.orders.orderSummary.isFileInUse,
                secondaryFileList: state => state.orders.secondaryFileList,
                systemDefaults: state => state.system.systemDefaults,
                region: state => state.orders.region,
            }),
            localSecurity(){
                return this.securitySettings.findValues(["AllowEditSalesRep", "AllowEditContacts","CanChangeEscrowAcct", "AdditionalFields_ScreenAccess", "AllowDepositDelete", "AllowDeleteCheck", "Notes_ScreenAccess"]);
            },
            isCriticalNotesVisible() { return this.localSecurity.Notes_ScreenAccess !== 0 },
            readOnly () { return this.isPageReadOnly || this.isOrderLocked || _.getBool(this, "userAccess.readOnly"); },
            displayCustomData () { return this.localSecurity.AdditionalFields_ScreenAccess !== UserScreenAccessLevel.None },
            customDataReadOnly() { return this.readOnly || this.localSecurity.AdditionalFields_ScreenAccess !== UserScreenAccessLevel.Full; },
            routeOrderId() { return _.getNumber(this, "$route.params.orderId", 0); },
            orderId() { return this.routeOrderId > 0 ? this.routeOrderId : this.storedOrderId; },
            optionFeeLabel() { return this.region.additionalDepositLabel || "Option Fee"; },
            hasEscrowChecksOrDeposits() { return _.get(this, "orderSummary.hasEscrowChecksOrDeposits", false); },
            hasEscrowChecks() { return _.get(this, "orderSummary.hasEscrowChecks", false); },
            receiptsOrDisbursementsPosted() { return _.get(this, "orderSummary.receiptsOrDisbursementsPosted", false); },
            getEscrowAccountToolTip() {
                if (this.hasEscrowChecksOrDeposits) {
                    return 'This file has Checks/Deposits so the Escrow Account must be changed using File Settings.';
                }
                if(this.receiptsOrDisbursementsPosted){
                    return 'This file has Issued Checks or Booked Deposits so the Escrow Account cannot be changed.';
                }
                return '';
                },
            isOrderLocked() { return _.get(this, "orderSummary.isLocked", false); },
            isEscrowLocked() { return _.get(this, "orderSummary.isEscrowLocked", false); },
            isPrimaryOrSecondaryFile() { return _.get(this, "order.isPrimaryFile", false) || _.get(this, "order.primaryOrdersID", 0) > 0; },
            closeTimeDisabled() { return !this.enableCloseTime || !DateTime.fromISO(this.order.closeDate).isValid || this.readOnly },
            locations () {
                const self = this;
                var placeOfClosingId = 0;
                if (self.placeOfClosing) placeOfClosingId = self.placeOfClosing.id;
                if (placeOfClosingId > 0) {
                    return self.lookupHelpers.getLocations(placeOfClosingId.toString());
                }
                return [];

            },
            canEditFundType(){ return _.isNil(this.originalValue.typeFundID); },
            canChangeMultipleBankAccount(){ return _.isEqual(_.getBool(this, "originalValue.multipleBankAccount", false), false); },
            orderStatuses() { return this.lookupHelpers.getLookupItems(this.lookupItems.STATUSES); },
            formattedStatus() {
                const self = this;
                let truncatedList = self.orderStatuses.slice(OrderStatus.New, OrderStatus.Old);
                let truncatedListSplit1 = self.orderStatuses.slice(OrderStatus.New, OrderStatus.Cancelled);
                let truncatedListSplit2 = self.orderStatuses.slice(OrderStatus.Cancelled, OrderStatus.Old);

                if (self.order.transactionTypeID === 3){
                    return [...truncatedListSplit1,
                        self.orderStatuses[OrderStatus.PolicyIssued],
                        ...truncatedListSplit2,
                        self.orderStatuses[OrderStatus.OnHold],
                        self.orderStatuses[OrderStatus.Old],
                        self.orderStatuses[OrderStatus.Legacy]
                    ];
                }

                return [...truncatedList,
                    self.orderStatuses[OrderStatus.OnHold],
                    self.orderStatuses[OrderStatus.Old],
                    self.orderStatuses[OrderStatus.Legacy]
                ];
            },
            dxThemeClass() {
                let routeTheme = _.get(this, "$route.meta.theme", null);
                return routeTheme ? `rq-dx-theme-${routeTheme}` : "";
            },
            titleCompanies() { return this.lookupHelpers.getTitleCompanies(null, this.order.titleCompanyCompanyID); },
            ...dateTimeSplitDependent({
                fields: {
                    date: {
                        calcCloseDate: 'order.closeDate',
                    },
                    time: {
                        calcCloseTime: 'order.closeTime',
                    },
                },
                sourceTimeZone: function() { return this.tenantTimeZone; },
                destinationTimeZone: function() { return this.clientTimeZone; },
            }),
            referenceTable() { return CustomDataReferenceTable.FileMain; },
            allowDeleteReceiptsAndDisbursements() { return this.localSecurity.AllowDepositDelete && this.localSecurity.AllowDeleteCheck && !this.orderSummary.receiptsOrDisbursementsPosted; },
            typeFunds() { return _.filter(this.lookupHelpers.getLookupItems(this.lookupItems.TYPE_FUNDS), i => i.id !== 0); },// && i.id !== this.IN_EARNEST_MONEY
            optionFeeTypeFunds() { return _.filter(this.lookupHelpers.getLookupItems(this.lookupItems.TYPE_FUNDS), i => i.id !== 0); },
            processTemplates() { return this.lookupHelpers.getLookupItems(this.lookupItems.WORKFLOW_PROCESS_TEMPLATES, this.order.regionID, this.order.workflowProcessTemplateID); },
            filteredTemplates() { return _.filter(this.processTemplates, item => item.regionID == this.order.regionID || item.regionID == this.globalRegionId)},
            sourceOfBusinesses() { return this.lookupHelpers.getLookupItems(this.lookupItems.SOURCE_OF_BUSINESSES); },
            escrowUnits() { return this.lookupHelpers.getLookupItems(this.lookupItems.ESCROW_UNITS, this.order.escrowUnitID); },
            underwriters() {
                let savedUnderwriterID = this.order.underwriterCompanyID;
                let lookupUnderwriters = this.lookupHelpers.getUnderwriters(this.order.regionID, true);
                return _.filter(lookupUnderwriters, item => !item.inactive || item.id === savedUnderwriterID);
            },
            orderCategories() { return  this.lookupHelpers.getLookupItems(this.lookupItems.ORDER_CATEGORIES, _.parseNumber(this.order.regionID, 0), _.parseNumber(this.order.orderCategoryID, 0)); },
            escrowAccounts() { return this.lookupHelpers.getLookupItems(this.lookupItems.ESCROW_ACCOUNTS, this.order.regionID, this.order.bankCompanyID); },
            titleUnits() { return this.lookupHelpers.getOrderLookupItems(this.lookupItems.TITLE_UNITS, this.order.titleUnitID ); },
            orderServices() { return this.lookupHelpers.getOrderServices(this.order.isAlta); },
            durations() { return this.lookupHelpers.getLookupItems(this.lookupItems.DURATIONS); },
            salesReps() { return this.lookupHelpers.getOrderLookupItems(this.lookupItems.SALES_REPS, this.order.salesRepresentativeStaffID); },
            escrowOfficers() { return this.lookupHelpers.getOrderLookupItems(this.lookupItems.ESCROW_OFFICERS, this.order.escrowOfficerStaffID); },
            titleOfficers() { return this.lookupHelpers.getOrderLookupItems(this.lookupItems.TITLE_OFFICERS, this.order.titleOfficerStaffID); },
            closingAgents() { return this.lookupHelpers.getOrderLookupItems(this.lookupItems.CLOSING_AGENTS, this.order.closeAgentStaffID); },
            escrowProcessors() { return this.lookupHelpers.getOrderLookupItems(this.lookupItems.ESCROW_PROCESSORS, this.order.escrowProcessorStaffID); },
            titleProcessors() { return this.lookupHelpers.getOrderLookupItems(this.lookupItems.TITLE_PROCESSORS, this.order.titleProcessorStaffID); },
            placeOfClosingLocations() { return this.lookupHelpers.getLocations(this.order.placeOfClosingCompanyId); },
            transactionTypes() { return this.lookupHelpers.getLookupItems(this.lookupItems.TRANSACTION_TYPES, null, this.order.transactionTypeID); },
            closingTypes() { return this.lookupHelpers.getLookupItems(this.lookupItems.CLOSING_TYPES, null, this.order.closingTypeID); },
            isCloseDateRequired() { return this.order.closeTime && !this.order.closeDate;},
            disableTransactionType() {return _.isEqual(this.order.transactionTypeID, 3) && this.hasEscrowChecksOrDeposits; }
        },

        watch: {
            order: {
                handler(newValue, oldValue) {
                    // This must re-align the close time display handler.
                    this.realignEnableCloseTime();
                }
            },
            "orderState.regionID": {
                handler(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    if(!_.isNil(newValue)) {
                        this.fetchData(false);
                    }                }
            },
            "orderState.escrowUnitID": {
                handler(newValue, oldValue) {
                    const self = this;
                    self.fetchData(false);
                }
            },
            "orderSummary.bankCompanyID": {
                handler(newValue, oldValue) {
                    const self = this;
                    self.fetchData(false);
                }
            },
            "calcCloseDate": {
                handler(newValue, oldValue) {
                    // This must re-align the close time display handler.
                    this.realignEnableCloseTime();
                }
            },
            "order.userDefinedDate1" (newValue) {
                const self = this;
                if (!self.isLoaded || self.order.status === OrderStatus.Cancelled || _.isNil(newValue)) return;
                let fileLedger = self.orderSummary.bankLedgers[_.size(self.orderSummary.bankLedgers)-1];
                if (fileLedger && !_.isEqual(fileLedger.fileBalance, 0)) {
                    let okHandler = () => {
                        self.order.userDefinedDate1 = null;
                        return true;
                    };
                    self.$dialog.messageBox("Cancel Error", `This file has a balance of <b>${accounting.formatMoney(fileLedger.fileBalance, { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } })}</b> and cannot be cancelled.`, okHandler);
                } else {
                    let okHandler = () => {
                        self.order.status = OrderStatus.Cancelled;
                        return true;
                    };
                    self.$dialog.confirm("Change File Status", "Would you like to change the status of the File to Cancelled?", okHandler);
                }
            },

            "order.status" (newValue, oldValue) {
                const self = this;
                if (self.isLoaded == true && newValue == OrderStatus.Cancelled) {
                    let fileLedger = self.orderSummary.bankLedgers[_.size(self.orderSummary.bankLedgers)-1];
                    if (fileLedger && !_.isEqual(fileLedger.fileBalance, 0)) {
                        let okHandler = () => {
                            self.order.status = oldValue;
                            return true;
                        };
                        self.$dialog.messageBox("Cancel Error", `This file has a balance of <b>${accounting.formatMoney(fileLedger.fileBalance, { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } })}</b> and cannot be cancelled.`, okHandler);
                    } else {
                        if (_.isNullOrEmpty(self.order.userDefinedDate1)) self.order.userDefinedDate1 = DateTimeHelper.now("MM/dd/yyyy");
                    }
                }
            },
            "order.salesRepresentativeStaffID" (newValue, oldValue) {this.trackStaffAssignmentsChange(newValue, oldValue);},
            "order.closeAgentStaffID" (newValue, oldValue) {this.trackStaffAssignmentsChange(newValue, oldValue);},
            "order.escrowOfficerStaffID" (newValue, oldValue) {this.trackStaffAssignmentsChange(newValue, oldValue);},
            "order.escrowProcessorStaffID" (newValue, oldValue) {this.trackStaffAssignmentsChange(newValue, oldValue);},
            "order.titleOfficerStaffID" (newValue, oldValue) {this.trackStaffAssignmentsChange(newValue, oldValue);},
            "order.titleProcessorStaffID" (newValue, oldValue) {this.trackStaffAssignmentsChange(newValue, oldValue);},
            "order.bankCompanyID" (newValue, oldValue)
            {
                if(newValue === oldValue) return;
                if(newValue != null)
                    this.showEarnestErrorBanner = false;
                if(newValue === null && this.order.earnestMoneyHeldBy != null)
                {
                    this.resetEarnestMoney();
                }
            },
            "order.earnestMoneyHeldBy" (newValue, oldValue)
            {
                if(newValue === oldValue) return;
                if(newValue === null && this.order.bankCompanyID === null)
                    this.showEarnestErrorBanner = false;
            },
            "order.multipleBankAccount" (newValue){
                const self = this;
                let originalMba = _.get(self, "originalValue.multipleBankAccount", false);
                if(newValue === true && newValue !== originalMba){

                    let ok = () => { self.save(); };
                    let cancel = () => {self.order.multipleBankAccount = false;};

                    self.messageBox("Confirm",
                    "Once this option is selected, the file cannot be reverted back to a single bank account.  Are you sure you want to use Multiple Bank Accounts for this File?",
                    ok, cancel);
                }
            },
            enableCloseTime: {
                handler(newValue, oldValue) {
                    this.onEnableCloseTimeChange(newValue, oldValue);
                }
            },
            orderId: {
                handler(newValue) {
                    this.fetchData();
                },
                immediate: true
            }
        },

        created () {
            const self = this;
            GlobalEventManager.onSave(self, self.onSave);
            GlobalEventManager.onCancel(self, self.onCancel);
            GlobalEventManager.onUpdateOrder(self, self.onUpdateOrder);

            self.$events.on("apply-template-success", e => {
                self.fetchData();
            });
        },

        validations() {
            const self = this;

            var result = {
                order: {
                    typeFundID: {
                        required:  requiredIf(function (vm) { return !this.order.lockEarnestMoneyTypeFund && this.order.earnestMoney && (this.order.earnestMoneyHeldBy === 0 || this.order.earnestMoneyHeldBy === 6); })
                    },
                    optionFeeTypeFundID: {
                        required:  requiredIf(function (vm) { return this.order.additionalDeposit && this.order.optionFeePayableTo === 0; })
                    },
                    service: {
                        required: requiredIf(function(vm) { return this.orderSummary.isConsolidatedFile == false; })
                    },
                    closeDate: {
                        required: requiredIf(function (vm) { return this.order.closeTime && !this.order.closeDate;})
                    }
                }
            }
            return result;
        },

        beforeUnmount () {
            const self = this;
            GlobalEventManager.unregister(self);
        },

        methods: {
            onEnableCloseTimeChange(newValue, oldValue) {
                const self = this;

                // Capture before value.
                let beforeDate = self.calcCloseDate;

                let checkDateChanged = (enabled) => {
                    let msg = enabled ? 'adding' : 'removing'
                    if (self.isLoaded) {
                        if (self.calcCloseDate != beforeDate) {
                            self.$toast.warn({ message: `Close date has changed due to ${msg} the close time...` });
                        }
                    }
                }

                let enabler = () => {
                    if (self.calcCloseTime == null) {
                        self.calcCloseTime = DateTime.fromISO(self.calcCloseDate).startOf('day').plus({hours: 12}).toFormat("yyyy-MM-dd'T'HH:mm:ss");
                    }

                    checkDateChanged(true);
                }
                let disabler = () => {
                    self.calcCloseTime = null;

                    checkDateChanged(false);
                }

                // Only prompt if the page is loaded (driven by user action, not order initialization).
                // For now, only put the prompt in place when ENABLING... otherwise, we would have infinite recursion.
                if (newValue && self.isLoaded) {

                    let actions = newValue
                        ? {
                            onOk: () => { enabler(); return true; },
                            onCancel: () => { self.enableCloseTime = oldValue; disabler(); }
                        }
                        : {
                            onOk: () => { disabler(); return true; },
                            onCancel: () => { self.enableCloseTime = oldValue; enabler(); }
                        };

                    let msg = self.enableCloseTime ? 'Adding' : 'Removing'
                    self.$dialog.confirm(`${msg} close time`,
                        `Warning: ${msg} a close time may modify the close date, based on your current time zone.`,
                        actions.onOk,
                        actions.onCancel);
                }
                else if (!newValue) {
                    disabler();
                }
                else {
                    enabler();
                }
            },

            realignEnableCloseTime() {
                const self = this;

                if (self.calcCloseTime == null) {
                    self.enableCloseTime = false;
                }
                else {
                    self.enableCloseTime = true;
                }
            },

            fetchData (refresh=false) {
                const self = this;
                self.isLoaded = false;
                if(_.isEmpty(self.orderState)) {
                    let storePromise = self.$store.dispatch(ORDER_ACTIONS.GET_ORDER, { orderId: self.orderId, refresh });
                    self.$rqBusy.wait(storePromise)
                        .then(data => {
                            self.initialize(data);
                        });
                }
                else {
                    self.initialize(self.orderState);
                }
            },

            initialize(data) {
                let self = this;
                let titleCompanyDdbInstance = _.get(self, "$refs.titleCompanyDropDownBox.instance", null);
                if(titleCompanyDdbInstance) titleCompanyDdbInstance.repaint();
                // self.findDepositData();
                self.fetchListingAgentConfiguration();
                self.fetchSellingAgentConfiguration();
                self.order = new OrderMainDto(data);
                self.originalValue = new OrderMainDto(data);
                // self.canSeeMultipleBankAccount = data.canSetMultipleBankAccounts || false;
                self.promptStaffAssignmentsChange = false;

                if (!(self.region.additionalDepositLabel === "Option Fee")) {
                    self.optionFeePayableToOptions = [
                    { id: 3, name: "Buyer's Real Estate Agent" },
                    { id: 4, name: "Lender" },
                    { id: 5, name: "No One or Not Listed" },
                    { id: 1, name: "Seller" },
                    { id: 2, name: "Seller's Real Estate Agent" },
                    { id: 0, name: "Title Company" }]
                }

                // Update EM option payable to match EM held by
                if (self.order.optionFeePayableTo === null)
                    self.order.optionFeePayableTo = self.order.earnestMoneyHeldBy;

                self.$nextTick(() => {
                    self.isLoaded = true;
                });

                if(!self.fileInUse && self.isCriticalNotesVisible)
                    this.$store.commit(ORDER_MUTATIONS.SET_ORDER_CRITICAL_NOTE_MODAL_OPEN, {open: true, autoOpen: true});
            },

            fetchListingAgentConfiguration()
            {
                const self = this;
                if(_.get(self, "systemDefaults.useSellerRealtorAsListingAgent", false))
                {
                    for(let i = 0; i< self.earnestMoneyHolderOptions.length; i++)
                    {
                        if(self.earnestMoneyHolderOptions[i].name === "Seller's Real Estate Agent")
                        {
                                self.earnestMoneyHolderOptions[i].name = "Listing Agent";
                                break;
                        }
                    }
                }
            },

            fetchSellingAgentConfiguration()
            {
                const self = this;
                if(_.get(self, "systemDefaults.useBuyerRealtorAsSellingAgent", false))
                {
                    for(let i = 0; i< self.earnestMoneyHolderOptions.length; i++)
                    {
                        if(self.earnestMoneyHolderOptions[i].name === "Buyer's Real Estate Agent")
                        {
                                self.earnestMoneyHolderOptions[i].name = "Selling Agent";
                                break;
                        }
                    }
                }
            },

            validateComponentInputs() {
                const self = this;
                self.validationErrors = [];
                if (_.isNil(self.order.closeDate) && !_.isNil(self.order.closeTime)) self.validationErrors.push("Select Close Date or Remove Close Time");
                if (!self.$refs.refPlaceOfClosing.isValid) self.validationErrors.push("Select a valid Place of Closing");
                if (!self.$refs.refSettlementAgent.isValid) self.validationErrors.push("Select a valid Settlement Agent");
                return self.validationErrors.length === 0;
            },

            trackStaffAssignmentsChange (newValue, oldValue) {
                if (!this.isLoaded || oldValue === newValue || (_.isNil(oldValue) && _.isNil(newValue))) return;
                this.promptStaffAssignmentsChange = true;
            },

            onSave (args) {
                const self = this;

                if (self.readOnly || self.isOrderLocked) {
                    GlobalEventManager.saveCompleted({ success: true });
                    return;
                }

                if (args && args.userInitiated) {
                    if (self.promptStaffAssignmentsChange) {
                        self.$api.OrderWorkflowApi.getTasks(self.orderId)
                            .then(data => {
                                let tasks = data;

                                let ok = () => {
                                    self.order.updateTaskAssignments = true;
                                    self.save(args);
                                };
                                let cancel = () => {self.save(args);};

                                // Prompt if there are tasks associated with order
                                if (tasks.length > 0)
                                    self.messageBox("Reassign Tasks", "Reassign appropriate tasks to staff members from File Main?", ok, cancel);
                                else
                                    self.save(args);
                            });
                    } else {
                        self.save(args);
                    }
                } else {
                    self.save({ noRefresh:true, ...args });
                }

            },

            findLookupName (lookups, toFind) {
                var item = _.find(lookups, toFind);
                if (typeof (item) !== "undefined" && item != null) {return item.name;}
                return null;
            },

            save (args) {
                const self = this;
                self.showValidationError = true;
                self.v$.$touch();
                self.hasErrors = self.v$.$error;
                _.invoke(self, "$refs.customDataContainer.validate");
                if (self.hasErrors || !self.customDataIsValid) {
                    self.escrowErrorMessage = "Please provide all required fields.";
                    GlobalEventManager.saveCompleted({ success: false, abort: true });
                    return;
                }

                if (self.readOnly || self.isOrderLocked) {
                    GlobalEventManager.saveCompleted({ success: true });
                    return;
                }

                if (self.showEarnestErrorBanner) {
                    self.$toast.error({ message: "Failed to save file. Please correct any errors." });
                    GlobalEventManager.saveCompleted({ success: false });
                    return;
                }

                // if (self.$rqError.scopeHasErrors(true)) {
                //     self.$toast.error({ message: "Save skipped due to errors encountered on the page." });
                //     GlobalEventManager.saveCompleted({ success: false });
                //     return;
                // }

                if (!self.validateComponentInputs() || !self.customDataIsValid) {
                    GlobalEventManager.saveCompleted({ success: false });
                    return;
                }

                let userInitiated = _.get(args, "userInitiated", false);

                let changes = self.getAuditChanges(self.originalValue.toEntity(), self.order.toEntity());
                let customDataChanges = _.differenceWith(self.customData, self.originalCustomData, _.isEqual);
                let success = true;
                if (changes.length > 0 || customDataChanges.length > 0) {
                    let storePromise = changes.length > 0 ? self.$store.dispatch(ORDER_ACTIONS.UPDATE_ORDER_MAIN, { order: self.order, changes }) : Promise.resolve();
                    self.$rqBusy.wait(storePromise)
                        .then(() => {
                            return self.$rqBusy.wait(self.$api.CustomDataApi.saveCustomData(customDataChanges));
                        })
                        .then(async () => {
                            _.invoke(self, "$refs.customDataContainer.initialize");
                            self.originalValue = new OrderMainDto(self.order);
                            self.$toast.success({ message: 'File Saved Successfully' });
                            if (self.isPrimaryOrSecondaryFile && _.some(changes, {name: "friendlyName"})) {
                                self.resetSecondaryFileList();
                            }
                            if (_.parseBool(self.order.isPrimaryFile) && _.some(changes, {name: "status", new: 3})) {
                                let statusIDs = _.pull(_.map(self.orderStatuses, "id"), OrderStatus.Cancelled);
                                let unCancelledCount = await self.$api.OrdersApi.getSecondaryOrderCount(self.orderId, statusIDs);
                                if (unCancelledCount > 0) {
                                    self.$dialog.messageBox("Cancel Error", `${unCancelledCount} Secondary File${unCancelledCount > 1 ? 's have' : ' has'} a balance and cannot be cancelled.`);
                                }
                            }
                        })
                        .catch(errorInfo => {
                            console.error("Failed to save File.", errorInfo);
                            self.$toast.error({ message: errorInfo.errorMessage });
                            success = false;
                        })
                        .finally(() => {
                            GlobalEventManager.saveCompleted({ success });
                        });
                } else {
                    // No changes -- display message (if necessary) and allow navigation
                    if (userInitiated) self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({ success });
                }
            },

            onCancel (args) {
                this.cancel();
            },

            onUpdateOrder() {
                this.fetchData();
            },

            cancel () {
                const self = this;
                _.invoke(self, "$refs.customDataContainer.resetFieldValidations");
                let hasChanges = () => {
                    let changes = self.getAuditChanges(self.originalValue.toEntity(), self.order.toEntity());
                    let customDataChanges = _.differenceWith(self.customData, self.originalCustomData, _.isEqual);
                    return changes.length > 0 || customDataChanges.length > 0;
                };
                GlobalEventManager.confirmCancel({
                                                    hasChanges,
                                                    callback: () => {
                                                        self.fetchData();
                                                        _.invoke(self, "$refs.customDataContainer.initialize");
                                                    }
                                                });
            },

            onEarnestMoneyChanged(){
                const self = this;

                if(_.isNil(self.order.earnestMoney) || self.order.earnestMoney === 0) return;
                if(self.order.earnestMoney === self.originalValue.earnestMoney) return;

                let okHandler = function(e) {
                    let comp = e.component;
                    self.order.earnestMoneyHeldBy = comp.earnestMoneyHeldBy || 0;
                    if(self.order.earnestMoneyHeldBy === 0){
                        if(_.isNil(self.order.bankCompanyID)){
                            self.resetEarnestMoney();
                            return;
                        }
                        self.order.typeFundID = comp.typeFundID;
                    }
                    if(self.order.earnestMoneyHeldBy === 2 || self.order.earnestMoneyHeldBy == 3)
                        self.order.deductEarnestMoneyFromCommission = comp.deductAmount
                }

                self.$dialog.open({
                    title: `Earnest Money`,
                    height: 373,
                    width: 750,
                    draggable: false,
                    scrollable: false,
                    component: EarnestMoney,
                    args: { order: self.order, typeFundID: self.originalValue.typeFundID, earnestMoneyHolderOptions: self.earnestMoneyHolderOptions, escrowDepositFundOptions:self.lookupHelpers.getLookupItems(self.lookupItems.TYPE_FUNDS) },
                    onOk: okHandler
                });
            },

            onEarnestMoneyHolderChanged(e){
                const self = this;
                self.showEarnestErrorBanner = false;
                let emHolder = self.order.earnestMoneyHeldBy;
                if (self.region.additionalDepositLabel !== "Option Fee" || emHolder === 0 || emHolder === 1)
                    self.order.optionFeePayableTo = emHolder;
                if(_.isNil(e.event) || _.parseNumber(e.value, 0) !== 0 || !_.isNil(self.order.bankCompanyID)) return;
                self.resetEarnestMoney();
            },

            onEscrowAccountChange(e) {
                const self = this;
                if(_.isNil(e.event) || _.isNil(e.previousValue)) return;
                //changing escrow acct has big consequences so I make a quick call to update the order summary to see if any checks/deposits have been created recently
                self.$store.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true)
                    .then((summary) => {
                        if(summary.receiptsOrDisbursementsPosted){
                            let okHandler = (e) => {
                                self.order.bankCompanyID = self.originalValue.bankCompanyID;
                            }

                            self.$dialog.open({
                                title: "WARNING",
                                height: "auto",
                                width: 400,
                                okOnly: true,
                                resizable: true,
                                scrollable: false,
                                adaptive: true,
                                autoFocusOkButton: true,
                                component: {
                                    name: "MessageBox",
                                    template: `<div class="rq-message-box dialog-confirm-message">This file has issued checks Checks or booked Deposits so the Escrow Account cannot be changed.</div>`
                                },
                                onOk: okHandler,
                            });
                            return true;
                        }

                        if (summary.hasEscrowChecksOrDeposits) {
                            let okHandler = (e) => {
                                self.order.bankCompanyID = self.originalValue.bankCompanyID;
                            }

                            self.$dialog.open({
                                title: "WARNING",
                                height: "auto",
                                width: 400,
                                okOnly: true,
                                resizable: true,
                                scrollable: false,
                                adaptive: true,
                                autoFocusOkButton: true,
                                component: {
                                    name: "MessageBox",
                                    template: `<div class="rq-message-box dialog-confirm-message">This file has Checks/Deposits so the Escrow Account must be changed using File Settings.</div>`
                                },
                                onOk: okHandler,
                            });
                            return true;
                        }
                    })
                    .catch(error => {
                        self.errorMessage = e.errorMessage;
                        return true;
                    });
            },

            resetEarnestMoney(){
                const self = this;
                self.showEarnestErrorBanner = true;
                self.order.typeFundID = null;
                self.escrowErrorMessage = "Escrow Account must be selected when Title Company is the holder.  No deposit has been created.";
                //self.validationErrors.push("Cannot set Earnest Money Holder to Title Company if Escrow Account is not set.") ;
            },

            resetSecondaryFileList(){
                const self = this;
                let list = _.cloneDeep(self.secondaryFileList);
                let item = _.find(list, {ordersID: self.order.ordersID});
                if (item) {
                    item.friendlyName = self.order.friendlyName;
                    self.$store.commit(ORDER_MUTATIONS.SET_ORDER_SECONDARY_FILE_LIST, list);
                }
            },

            onServiceChange(e){
                const self = this;
                if(_.isNil(e.event) || _.isNil(e.value) || _.isNil(e.previousValue)) return;
                let ok = () => {
                    self.save();
                };
                let cancel = () => {
                    self.order.service = e.previousValue;
                };
                let fromServiceName = self.lookupHelpers.getLookupItemName(self.lookupItems.ORDER_SERVICES, e.previousValue) || '(Blank)';
                let toServiceName = self.lookupHelpers.getLookupItemName(self.lookupItems.ORDER_SERVICES, e.value) || '(Blank)';
                this.messageBox("Changing Service", `Are you sure you want to change the service type from <strong>${fromServiceName}</strong> to <strong>${toServiceName}</strong>?`, ok, cancel);
            },

            onUnderwriterChange(e){
                if(_.isNil(e.event)) return;
                const self = this;
                if (_.isNil(self.originalValue.underwriterCompanyID)) return;

                let ok = () => {
                    self.$store.dispatch(COMMITMENT_ACTIONS.CLEAR_POLICY_NUMBERS)
                        .then(() => {
                            self.order.hasPolicyNumber = false;
                        })
                        .catch(errorInfo => {
                            console.error("Failed to clear policy no.", errorInfo);
                            self.order.underwriterCompanyID = self.originalValue.underwriterCompanyID;
                            self.order.underwriterCompanyName = self.originalValue.underwriterCompanyName;
                        });

                    _.invoke(self, "$refs.underwriter.instance.focus");
                };
                let cancel = () => {
                    self.order.underwriterCompanyID = self.originalValue.underwriterCompanyID;
                    self.order.underwriterCompanyName = self.originalValue.underwriterCompanyName;
                    _.invoke(self, "$refs.underwriter.instance.focus");
                };

                if (self.order.hasPolicyNumber)
                    this.messageBox("Changing Underwriter", "Changing the Underwriter will clear the Owner and Loan Policy numbers that are currently assigned to the File.  Continue?", ok, cancel);
            },

            // RQO-17256: Additional Deposit deducts from Earnest Money when fund type is "In Earnest Money"
            onAdditionalDepositChange(e) {
                // If included in earnest money fund type we deduct and adjust the earnest money value
                if (this.order.optionFeeTypeFundID === this.IN_EARNEST_MONEY) {
                    this.order.earnestMoney = this.calculatedEarnestMoney(this.order.additionalDeposit);
                }
            },

            onOptionEscrowFundTypeChange(e) {
                // If included in earnest money fund type we deduct and adjust the earnest money value
                if (e.event && e.value === this.IN_EARNEST_MONEY) {
                    this.order.earnestMoney = this.calculatedEarnestMoney(this.order.additionalDeposit);
                }
            },

            calculatedEarnestMoney(deduction) {
                let netEarnestMoney = this.order.earnestMoney - _.parseNumber(deduction, 0);
                return netEarnestMoney >= 0 ? netEarnestMoney : 0; // note: earnest money cannot be negative
            },

            messageBox (title, message, ok, cancel) {
                const self = this
                self.$dialog.confirm(
                    title,
                    message,
                    ok,
                    cancel,
                    {cancelTitle: "No", okTitle: "Yes"});
            },

            getCompanyAddress(item) {
                if(_.isEmpty(item)) return "";
                let addressParts = [item.address1, item.address2, item.city, item.state, item.zip];
                return _.joinParts(addressParts, ", ");
            },

            onCustomDataLoaded(e) {
                let self = this;
                self.originalCustomData = _.cloneDeep(e);
            },

            onFundDateChanged(e) {
                const self = this;
                if(_.isNil(e?.event) || !_.isDateOnWeekend(e?.value)) return;

                const ok = () => self.order.fundDate = e.value;
                const cancel = () => self.order.fundDate = e.previousValue;

                self.$dialog.confirm(
                    "Confirm",
                    "Are you sure you want to schedule a fund/disbursement date on a weekend?",
                    ok,
                    cancel,
                    { cancelTitle: 'No', okTitle: 'Yes'}
                );
            },

            onCloseDateChanged(e){
                const self = this;
                if(_.isNil(e?.event) || !_.isDateOnWeekend(e?.value)) return;

                let ok = () => self.order.closeDate = e.value;
                let cancel = () => self.order.closeDate = e.previousValue;

                self.$dialog.confirm(
                    "Confirm Changes",
                    `Are you sure you want to schedule a closing on a weekend?`,
                    ok,
                    cancel,
                    {cancelTitle: "No", okTitle: "Yes"}
                );
            }
        }
    };
</script>

<style>
    .order-main-content > .rq-section:last-child .rq-section-body { padding-bottom: 250px; }
</style>
